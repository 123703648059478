<!-- header.component.html -->
<header>
  <div class="weboldal-cime">
    <a href="/"><img height="97px" src="/assets/logo.png" /></a>
    <h1>{{ title }}</h1>
  </div>

  <!-- Desktop Nav -->
  <nav class="desktop-nav">
    <ul>
      <li><a href="/">Nyitóoldal</a></li>
      <li><a href="/#/edzoink">Edzőink</a></li>
      <li><a href="/#/galeria">Galéria</a></li>
      <li><a href="/#/hirek">Aktuális</a></li>
      <li><a href="/#/kapcsolat">Kapcsolat</a></li>
    </ul>
  </nav>

  <!-- Mobile Hamburger Menu Icon -->
  <div class="hamburger" (click)="toggleMobileMenu()">
    <span></span>
    <span></span>
    <span></span>
  </div>

  <!-- Mobile Nav Overlay -->
  <div class="mobile-nav" *ngIf="isMobileMenuOpen" [ngClass]="{'open':isMobileMenuOpen}">
    <div class="header-contact">
      <div class="contact-phone"><span><i class="ph ph-phone"
            style="font-size: 24px; padding-top: 4px"></i></span><span>+36706370380</span>
      </div>
      <div class="contact-mail"><span><i class="ph ph-envelope"
            style="font-size: 24px; padding-top: 4px"></i></span><span>vacivizilabdase@gmail.com</span></div>
    </div>
    <div class="mobile-nav-list">
      <div class="close-btn" (click)="toggleMobileMenu()">✕</div>
      <ul>
        <li><a href="/" (click)="toggleMobileMenu()">Nyitóoldal</a></li>
        <li><a href="/#/edzoink" (click)="toggleMobileMenu()">Edzőink</a></li>
        <li><a href="/#/galeria" (click)="toggleMobileMenu()">Galéria</a></li>
        <li><a href="/#/hirek" (click)="toggleMobileMenu()">Aktuális</a></li>
        <li><a href="/#/kapcsolat" (click)="toggleMobileMenu()">Kapcsolat</a></li>
      </ul>
    </div>
  </div>
</header>