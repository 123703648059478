<section class="article-section">
    <div class="title-bg">
        <div class="title-overlay">
            <h1 class="title is-1 has-text-white">Híreink</h1>
        </div>
    </div>
    <div class="container">
        <div class="white-bg">
            <h1 class="title is-4">{{ blog.title }}</h1>
            <hr />
            <div class="blog-img">
                <img [src]="blog.imgSrc" />
            </div>
            <p [innerHTML]="blog.content"></p>
        </div>
    </div>
</section>