<section class="article-section">
    <div class="title-bg">
        <div class="title-overlay">
            <h1 class="title is-1 has-text-white">Csatlakozz hozzánk!</h1>
        </div>
    </div>
    <div class="container">
        <div class="white-bg">
            <img src="./../../assets/team-vac.jpg" />
            <div class="text-center">
                <span>❗️</span>
                <p class="text-center">Csatlakozási lehetőség</p>
                <span>❗️</span>
            </div>

            <p>Legyen egy jó dobásod, csatlakozz egyesületünkhöz, hogy nyerj! 🏆🏆🏆</p>

            <p>⛔️ Nem, nem azt ígérjük, hogy minden mérkőzést megnyerünk…☝️</p>

            <p>… azonban jó eséllyel:<br/>
            🤽‍♂️ nyerhetsz barátokat és egy csapat részévé válsz<br/>
            🤽‍♀️ nyersz majd rengeteg tapasztalatot abban, hogy hogyan kell méltósággal győzni, illetve emelt fővel veszíteni<br/>
            🤽‍♂️ nyersz, mert alkalmazkodást és kitartást tanulsz, a sport és a mozgás életed részévé válik majd</p>

            <p>Mindezeket persze az úszás és a vízilabdázás magas színvonalú elsajátítása mellett ☝️</p>

            <p>Professzionális szakmai háttérrel, az ország legsikeresebb csapatsportjával ismerkedhetsz meg nálunk, hogy később akár Te legyél a jövő nagy bajnoka! 🤩🤽‍♀️🤽‍♂️🤽‍♀️🤽‍♂️🤩</p>
            
            <div class="text-center">
                <p><b>Jelentkezz most, ne maradj le!</b></p>
            </div>
        </div>
    </div>
</section>