<footer class="footer">
    <div class="columns">
        <div class="column">
            <div class="footer-item has-text-white">
                <img height="97px" src="/assets/logo.png" />
                <p class="has-text-white"><span class="has-text-weight-bold">Váci Vízilabda SE</span></p>
                <p>2600 Vác, Ady Endre sétány 16.</p>
                <p><span class="has-text-weight-bold">E-mail: </span>vacivizilabdase@gmail.com</p>
                <p><span class="has-text-weight-bold">Telefonszám: </span>+36706370380</p>
                <p><span class="has-text-weight-bold">Adószám:</span> 18671685-2-13</p>
                <p><span class="has-text-weight-bold">Bankszámla szám:</span> 11742094-20150279</p>
            </div>
        </div>
        <div class="column">
            <div class="footer-item">
                <h1 class="title is-4 has-text-white">Navigáció</h1>
                <a href="/">Nyitóoldal</a>
                <a href="/#/edzoink">Edzőink</a>
                <a href="/#/galeria">Galéria</a>
                <a href="/#/hirek">Aktuális</a>
                <a href="/#/kapcsolat">Kapcsolat</a>

            </div>
        </div>
        <div class="column">
            <div class="footer-item">
                <h1 class="title is-4 has-text-white">Információk</h1>
                <a href="/#/dokumentumok">Dokumentumok</a>
                <a href="/#/blog/3">SZJA 1%</a>
            </div>
        </div>
        <div class="column">
            <div class="footer-item">
                <h1 class="title is-4 has-text-white">Kövess minket</h1>
                <div class="social-icons">
                    <a target="_blank" href="https://www.facebook.com/vacivizilabda"><img width="20px" src="./../../assets/facebook-icon.png"></a>
                    <a target="_blank" href="https://www.instagram.com/vacivizilabda.se/"><img width="20px" src="./../../assets/instagram-icon.png"></a>
                </div>
            </div>
        </div>
    </div>
</footer>