import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  previousFiles = [
    { link: "/assets/documents/VAC_2023MUK_SFP_jh.hatarozat.pdf", title: "TAO jóváhagyó határozat 2023/2024" },
    { link: "/assets/documents/VAC_2023MUK_SFP_jh.kerelem_publ.pdf", title: "SFP 2023/2024" },
    { link: "/assets/documents/sfp_jh_000_10023_2022_mvlsz_2022-03-11_1647005078_1647007450.pdf", title: "TAO jóváhagyó határozat 2022/2023" },
    { link: "/assets/documents/sfp_publikus_10023_20220317_1647500720.pdf", title: "SFP 2022/2023" },
    { link: "/assets/documents/sfp_jh_000_08023_2021_mvlsz_2021-04-07_1617779172_1617957388.pdf", title: "TAO jóváhagyó határozat 2021/2022" },
    { link: "/assets/documents/sfp_publikus_08023_20210410_1618030073.pdf", title: "SFP 2021/2022" },
    { link: "/assets/documents/SFP_jovahagyo_hatarozat2020_2021.pdf", title: "TAO jóváhagyó határozat 2020/2021" },
    { link: "/assets/documents/SFP_2020_2021.pdf", title: "SFP 2020/2021" }
  ];

  /*
    <a href="/assets/documents/Alapszabaly-2020.03.13.pdf" target="_blank" rel="noopener">
            <h4>Alapszabály</h4>
        </a>
        <a href="/assets/documents/sportorvosi-nyilatkozatok.pdf" target="_blank" rel="noopener">
            <h3>Sportorvosi nyilatkozatok</h3>
            <p></p>
        </a>
  */

  files = [
    { link: "/assets/documents/Alapszabaly-2020.03.13.pdf", title: "Alapszabály" },
    { link: "/assets/documents/sportorvosi-nyilatkozatok.pdf", title: "Sportorvosi nyilatkozatok" }
  ];

  constructor() { }

  ngOnInit(): void {
  }
}
